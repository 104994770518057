<template>
  <article
    class="product__item__related"
    @click="click()"
  >
    <div class="product__item__related__image">
      <Badge
        v-if="summaryitem.category === 'outlet' && !summaryitem.$$price.type > 1"
        type="outlet"
      >
        {{ $t('items.cat.outlet') }}
      </Badge>
      <Badge
        v-if="summaryitem.$$price.type > 1"
        type="promo"
      >
        {{ $t('general.promo') }}
      </Badge>
      <img
        v-if="summaryitem.image"
        :src="summaryitem.image"
        loading="lazy"
      >
      <img
        v-if="!summaryitem.image"
        class="img-fluid img-thumbnail"
        width="220px"
        loading="lazy"
        src="@/assets/img/product_placeholder.jpg"
      >
    </div>
    <div class="product__item__related__content">
      <header>
        <h4>{{ summaryitem.subtitle ? summaryitem.subtitle : "" }}</h4>
        <h3>{{ summaryitem.title }}</h3>
      </header>

      <div class="product__item__related__tags">
        <span
          v-for="tag in summaryitem.tags"
          :key="tag"
          class="tag"
        >{{ tag }}</span>
      </div>
    </div>
    <div class="product__item__related__info">
      <div class="product__item__related__price">
        <div
          v-if="summaryitem.$$price.type > 1
            && (
              (showRetailPrices && summaryitem.$$price.oldmsrp)
              || (!showRetailPrices && summaryitem.$$price.oldprice)
            )"
          class="oldprice"
        >
          <price
            :price="showRetailPrices ? summaryitem.$$price.oldmsrp : summaryitem.$$price.oldprice"
            :currency="summaryitem.$$price.currency"
          />
        </div>

        <div class="newprice">
          <price
            :price="showRetailPrices ? summaryitem.$$price.msrp : summaryitem.$$price.price"
            :currency="summaryitem.$$price.currency"
          />
        </div>
      </div>

      <span class="product__item__related__about">
        <span>
          <label>{{ $t('items.stock') }}</label>
          <availability
            :availability="summaryitem.$$availability"
            quantity="1"
            size="small"
          />
        </span>

        <span v-if="summaryitem.variantattribute && summaryitem.variantattribute.value">
          <label>{{ attributeTranslations[summaryitem.variantattribute.attribute]
            ? attributeTranslations[summaryitem.variantattribute.attribute]
            : summaryitem.variantattribute.attribute }}</label>
          <span class="product__item__related__size">{{ summaryitem.variantattribute.value }}</span>
        </span>
      </span>
    </div>
  </article>
</template>

<style scoped></style>

<script>
import { mapState } from 'vuex';
import availability from '@/elements/Availability.vue';
import price from '@/elements/Price.vue';
import Badge from '@/elements/Badge.vue';

export default {
  components: {
    availability,
    price,
    Badge,
  },
  props: {
    summaryitem: {
      type: Object,
      default() {
        return {};
      },
    },
    gtagList: {
      type: String,
      default: null,
    },
    gtagPosition: {
      type: [String, Number],
      default: null,
    },
  },
  computed: {
    ...mapState({
      attributeTranslations: state => state.language.attributeTranslations,
      showRetailPrices: state => state.items.showRetailPrices,
    }),
  },
  methods: {
    click() {
      this.$emit('click');
      this.$gtag.event('select_content', {
        content_type: 'product',
        items: [
          {
            id: this.summaryitem.externalid,
            name: this.summaryitem.description,
            brand: this.summaryitem.subtitle,
            price: this.summaryitem.$$price.price,
            quantity: 1,
            list_name: this.gtagList,
            list_position: this.gtagPosition,
          },
        ],
      });
    },
  },
};
</script>
