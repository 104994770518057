<template>
  <bcf-aside
    @close="close"
  >
    <bcfAsideHeader :close="close">
      <h4 v-if="!pending">
        <span
          v-if="showBack"
          class="main__title__action--left"
          @click.prevent="changeCurrentPath(parentPaths[parentPaths.length - 1].path)"
        >
          <i class="uil uil-arrow-left" />
        </span>
        {{ result.title }}
      </h4>
    </bcfAsideHeader>
    <bcfAsideContent v-if="error">
      <message type="error">
        {{ $t(error) }}
      </message>
    </bcfAsideContent>
    <bcfAsideContent v-else>
      <loader v-if="pending" />
      <div
        v-else
        class="row"
      >
        <emptystate
          v-if="result.children.length === 0"
          icon="file-search-alt"
          class="col"
        >
          {{ $t('account.about.promo.empty') }}
        </emptystate>
        <div
          v-else
          class="col"
        >
          <list-item
            v-for="item in folders"
            :key="item.id"
            icon-before
            arrow
          >
            <a
              href="#"
              class="pointer"
              @click.prevent="changeCurrentPath((currentPath ? '/' + currentPath : '') + '/' + item.name)"
            >
              <i class="uil uil-folder" />
              <div>
                {{ item.name }}
              </div>
            </a>
            <small class="folder-count">{{ ' (' + item.$$folder.count + ')' }}</small>
          </list-item>
          <div class="gallery">
            <a
              v-for="item in items"
              :key="item.id"
              :href="item.$$file.download"
              target="_blank"
              style="background-position: center; background-size: cover;"
              :style="[ item.$$file.thumb ? { backgroundImage : 'url('+item.$$file.thumb+')'} : { backgroundImage : 'url('+placeholder+')'}]"
              :title="item.name"
            />
          </div>
        </div>
      </div>
    </bcfAsideContent>
    <bcfAsideFooter>
      <span />
      <router-link
        class="button button--outline"
        :to="{name: 'sharepointItems', params: {driveId: driveId, itemId: currentPath}}"
        @click="close"
      >
        {{ $t('account.about.promo.goTo') }}
      </router-link>
    </bcfAsideFooter>
  </bcf-aside>
</template>
<style lang="scss">
small.folder-count {
  margin-left: 5px;
}
</style>
<script>
import ListItem from '@/elements/ListItem.vue';
import { getDriveItemItems, getDriveRootItems } from '@/api/sharepoint';
import loader from '@/elements/loader.vue';
import bcfAside from '@/components/bcfAside.vue';
import bcfAsideHeader from '@/components/bcfAsideHeader.vue';
import bcfAsideContent from '@/components/bcfAsideContent.vue';
import bcfAsideFooter from '@/components/bcfAsideFooter.vue';
import emptystate from '@/elements/EmptyState.vue';
import placeholder from '@/assets/img/product_placeholder.jpg';
import message from '@/components/Message.vue';

export default {
  name: 'SharepointAside',
  components: {
    ListItem,
    loader,
    bcfAside,
    bcfAsideHeader,
    bcfAsideContent,
    bcfAsideFooter,
    emptystate,
    // eslint-disable-next-line vue/no-unused-components
    placeholder,
    message,
  },
  props: {
    driveId: {
      type: String,
      default: null,
    },
    itemId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      result: null,
      pending: false,
      parentPath: '',
      currentPath: this.itemId,
      folders: [],
      items: [],
      parentPaths: [],
      rootPath: '',
      error: null,
    };
  },
  metaInfo() {
    return {
      title: this.$t('account.about.promo.title'),
    };
  },
  computed: {
    showBack() {
      return this.currentPath !== this.rootPath;
    },
  },
  watch: {
    $route() {
      this.loadItems();
    },
  },
  async mounted() {
    await this.loadItems();
    this.rootPath = this.currentPath;
  },
  methods: {
    imgFailed(event) {
      if (!event.target.failed) {
        event.target.failed = true;
        // eslint-disable-next-line global-require
        event.target.src = require('@/assets/img/product_placeholder.jpg');
      }
    },
    close() {
      this.$emit('close');
    },
    async loadItems() {
      this.pending = true;
      this.parentPaths = null;
      try {
        if (this.currentPath) {
          this.result = await getDriveItemItems(this.driveId, this.currentPath);
        } else {
          this.result = await getDriveRootItems(this.driveId);
        }
      } catch (e) {
        this.error = e.apiMessage;
      }

      if (this.result.parent.path) {
        this.parentPaths = this.result.parent.path.split('/');
        this.parentPaths = this.parentPaths.map((o, i) => {
          const name = decodeURI(o);
          let path = '';
          this.parentPaths.forEach((element, ia) => {
            if (ia <= i) {
              path += `/${element}`;
            }
          });
          return {
            name,
            path,
          };
        });
      }
      this.folders = this.result.children.filter(o => o.type === 'FOLDER');
      this.items = this.result.children.filter(o => o.type === 'ITEM');

      this.pending = false;
    },
    changeCurrentPath(currentPath) {
      this.currentPath = currentPath;
      this.loadItems();
    },
  },
};

</script>

<style lang="scss" scoped>
.gallery a {
  text-decoration: none;
  background: white;
  div {
    background-color: #00000082;
    color: white;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    padding: 1em;
    font-size: 0.8em;
    small {
      font-size: 0.7em;
    }
  }
}
</style>
