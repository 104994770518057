<template>
  <article
    class="product__item__related product__item__related--mini"
    @click="click"
  >
    <div class="product__item__related__image">
      <img
        v-if="summaryitem.image"
        :src="summaryitem.image"
        loading="lazy"
      >
      <img
        v-if="!summaryitem.image"
        class="img-fluid img-thumbnail"
        width="220px"
        loading="lazy"
        src="@/assets/img/product_placeholder.jpg"
      >
    </div>
    <div class="product__item__related__content">
      <header>
        <h4>{{ summaryitem.subtitle ? summaryitem.subtitle : "" }}</h4>
        <h3>{{ summaryitem.title }}</h3>
      </header>
    </div>
    <div class="product__item__related__info">
      <div class="product__item__related__price">
        <div
          v-if="summaryitem.$$price.type > 1
            && (
              (showRetailPrices && summaryitem.$$price.oldmsrp)
              || (!showRetailPrices && summaryitem.$$price.oldprice)
            )"
          class="oldprice"
        >
          <price
            :price="showRetailPrices ? summaryitem.$$price.oldmsrp : summaryitem.$$price.oldprice"
            :currency="summaryitem.$$price.currency"
          />
        </div>

        <div class="newprice">
          <price
            :price="showRetailPrices ? summaryitem.$$price.msrp : summaryitem.$$price.price"
            :currency="summaryitem.$$price.currency"
          />
        </div>
      </div>
    </div>
  </article>
</template>

<style scoped></style>

<script>
import { mapState } from 'vuex';
import price from '@/elements/Price.vue';

export default {
  components: {
    price,
  },
  props: {
    summaryitem: {
      type: Object,
      default() {
        return {};
      },
    },
    gtagList: {
      type: String,
      default: null,
    },
    gtagPosition: {
      type: [String, Number],
      default: null,
    },
  },
  computed: {
    ...mapState({
      attributeTranslations: state => state.language.attributeTranslations,
      showRetailPrices: state => state.items.showRetailPrices,
    }),
  },
  mounted() {
    console.log(this.summaryitem.$$price, this.summaryitem);
  },
  methods: {
    click() {
      this.$emit('click');
      this.$gtag.event('select_content', {
        content_type: 'product',
        items: [
          {
            id: this.summaryitem.externalid,
            name: this.summaryitem.description,
            brand: this.summaryitem.subtitle,
            price: this.summaryitem.$$price.price,
            quantity: 1,
            list_name: this.gtagList,
            list_position: this.gtagPosition,
          },
        ],
      });
    },
  },
};
</script>
