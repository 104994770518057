export const scrollTo = (div) => {
  const element = document.getElementById(div);
  const headerOffset = 90;
  const headerOffsetInner = 55;
  const x = document.getElementsByClassName('main__inner')[0];
  const currentScroll = document.documentElement.scrollTop;
  const currentScrollInner = x.scrollTop;
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition - headerOffset + currentScroll;
  const offsetPositionInner = elementPosition - headerOffsetInner + currentScrollInner;

  x.scroll({
    top: offsetPositionInner,
    behavior: 'smooth',
  });
  window.scroll({
    top: offsetPosition,
    behavior: 'smooth',
  });
};

export default scrollTo;
