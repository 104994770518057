<template>
  <div>
    <div
      v-for="(variant, index) in variantFilters"
      :key="index + item.id"
    >
      <div
        v-if="variant.type === 'images'"
        class="select-options"
      >
        <h3 class="select-options__title">
          {{ variant.title }}
        </h3>

        <div
          v-for="(option) in variant.options"
          :key="option.relationId"
          v-title="option.title"
          class="select-options__item"
          :class="{'select-options__item--active': option.relationId === 'current'}"
          @click="goToItem(option)"
        >
          <span
            v-if="option.preorder"
            style="position: absolute; top: 0; right: 0; background: red; color: white; padding: 2px; border-radius: 50% 50%; height: 5px; width: 5px; margin: 3px"
          />
          <div
            v-if="option.different"
            style="position: absolute; bottom: 0; right: 0; background: white; color: white; width: 100%; height: 100%; opacity: 50%;"
          />
          <img
            v-if="option.image"
            :src="option.image"
          >
          <img
            v-else
            src="@/assets/img/product_placeholder.jpg"
          >
        </div>
      </div>
      <div
        v-if="variant.type === 'select'"
        class="select-options select-options--small"
      >
        <h3 class="select-options__title">
          {{ variant.title }}
          <a
            v-if="hasGeo && variant.titleAttribute === 'frame_size'"
            class="more-link"
            @click.prevent="scrollTo('geo')"
          >{{ $t('items.moreInformation') }}</a>
        </h3>
        <div
          v-if="variant.options.length > 1"
          class="select-dropdown"
        >
          <Multiselect
            :options="variant.options"
            :searchable="false"
            :show-labels="false"
            :value="variant.current"
            track-by="itemId"
            class="pointer"
            @select="goToItem"
          >
            <template
              slot="singleLabel"
              slot-scope="props"
            >
              {{ props.option.title }}
            </template>
            <template
              slot="option"
              slot-scope="props"
            >
              <div>
                <span>{{ props.option.title }} <span
                  v-if="props.option.preorder"
                  style="position: absolute; top: 15px; right: 0; background: red; color: white; padding: 2px; border-radius: 50% 50%; height: 3px; width: 3px; margin: 3px"
                /></span>
                <small>{{ props.option.small }}</small>
              </div>
            </template>
          </Multiselect>
        </div>
        <strong v-else>{{ variant.options[0].title }}</strong>
      </div>
      <div
        v-if="variant.type === 'grouped_select'"
        class="select-options select-options--small"
      >
        <h3 class="select-options__title">
          {{ variant.title }}
          <a
            v-if="hasGeo && variant.titleAttribute === 'frame_size'"
            class="more-link"
            @click.prevent="scrollTo('geo')"
          >{{ $t('items.moreInformation') }}</a>
        </h3>
        <div
          v-if="variant.options.length > 1"
          class="select-dropdown"
        >
          <Multiselect
            :options="variant.options"
            :searchable="false"
            :show-labels="false"
            :value="variant.current"
            track-by="itemId"
            class="pointer"
            group-values="options"
            group-label="label"
            @select="goToItem"
          >
            <template
              slot="singleLabel"
              slot-scope="props"
            >
              {{ props.option.title }}
            </template>
            <template
              slot="option"
              slot-scope="props"
            >
              <div v-if="props.option.$groupLabel">
                {{ props.option.$groupLabel }}
              </div>
              <div
                v-else
              >
                <span>{{ props.option.title }} <span
                  v-if="props.option.preorder"
                  style="position: absolute; top: 15px; right: 0; background: red; color: white; padding: 2px; border-radius: 50% 50%; height: 3px; width: 3px; margin: 3px"
                /></span>
                <small>{{ props.option.small }}</small>
              </div>
            </template>
          </Multiselect>
        </div>
        <strong v-else>{{ variant.options[0].title }}</strong>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Multiselect from 'vue-multiselect';
import { availabilityText } from '@/utils/cartUtils';
import { formatPrice } from '@/utils/pricingUtils';
import { scrollTo } from '@/utils/scrollUtils';

export default {
  components: {
    Multiselect,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    relations: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState({
      attributeTranslations: (state) => state.language.attributeTranslations,
      showRetailPrices: (state) => state.items.showRetailPrices,
      isPreviewMode: (state) => state.global.isPreviewMode,
    }),
    hasGeo() {
      return (!!this.item.attributes.images.geo_image1) || (this.item.attributes.excel_frame_geometry && Array.isArray(this.item.attributes.excel_frame_geometry));
    },
    variantFilters() {
      const variantFilters = [];
      if ((this.relations.otherdesigns && this.relations.otherdesigns.length > 0) || (this.relations.otherdesignsdifferentgroupset && this.relations.otherdesignsdifferentgroupset.length > 0)) {
        let otherdesigns = [
          ...this.relations.otherdesigns ? this.relations.otherdesigns.map((o) => ({
            relationId: o.relationId,
            itemId: o.itemId,
            title: o.description,
            image: o.image ? o.image : '',
            externalid: o.externalid,
            description: o.description,
            price: o.$$price.price,
            paint_des: o.attributes ? o.attributes.paint_des : null,
            preorder: o.attributes.preorder && ['yes', 'ja', 'oui', 'sì', 'si'].includes(o.attributes.preorder.toLowerCase()),
          })) : [],
          {
            relationId: 'current',
            itemId: this.item.id,
            title: this.item.description,
            image: this.item.image ? this.item.image : '',
            externalid: this.item.externalid,
            description: this.item.description,
            price: this.item.$$price.price,
            paint_des: this.item.attributes ? this.item.attributes.paint_des : null,
            preorder: this.item.attributes.preorder && ['yes', 'ja', 'oui', 'sì', 'si'].includes(this.item.attributes.preorder.toLowerCase()),
          },
          ...this.relations.otherdesignsdifferentgroupset ? this.relations.otherdesignsdifferentgroupset.map((o) => ({
            relationId: o.relationId,
            itemId: o.itemId,
            title: o.description,
            image: o.image ? o.image : '',
            externalid: o.externalid,
            description: o.description,
            price: o.$$price.price,
            preorder: o.attributes.preorder && ['yes', 'ja', 'oui', 'sì', 'si'].includes(o.attributes.preorder.toLowerCase()),
            paint_des: o.attributes ? o.attributes.paint_des : null,
            different: true,
          })) : [],
        ];
        // remove duplicates
        otherdesigns = otherdesigns.filter((value, index, self) => index === self.findIndex((t) => (
          t.paint_des === value.paint_des
        )));
        otherdesigns = otherdesigns.sort((a, b) => a.paint_des.localeCompare(b.paint_des));
        // otherdesigns = otherdesigns.sort((a, b) => ((a.different === b.different) ? 0 : a.different ? 1 : -1));
        variantFilters.push({
          title: this.$t('items.designVariations'),
          titleAttribute: 'color',
          type: 'images',
          options: otherdesigns.map((o, i) => ({
            ...o,
            index: i,
            type: 'otherdesigns',
          })).sort((o) => !o.different),
        });
        console.log(variantFilters);
      }

      if (this.item.variantattribute) {
        const currentSize = {
          relationId: 'current',
          itemId: this.item.id,
          title: this.item.variantattribute.value,
          externalid: this.item.externalid,
          description: this.item.description,
          price: this.item.$$price.price,
          priceDifference: null,
          small: availabilityText(this.item.$$availability, this.showRetailPrices),
          preorder: this.item.attributes.preorder && ['yes', 'ja', 'oui', 'sì', 'si'].includes(this.item.attributes.preorder.toLowerCase()),
        };
        let othersizes = [
          ...this.relations.othersizes ? this.relations.othersizes.map((o) => ({
            relationId: o.relationId,
            itemId: o.itemId,
            title: o.variantattribute.value,
            externalid: o.externalid,
            description: o.description,
            price: o.$$price.price,
            small: availabilityText(o.$$availability, this.showRetailPrices),
            preorder: o.attributes.preorder && ['yes', 'ja', 'oui', 'sì', 'si'].includes(o.attributes.preorder.toLowerCase()),
          })) : [],
          currentSize,
        ];
        othersizes = othersizes.sort((a, b) => {
          const sizes = [
            'xxxs',
            'xxs',
            'xs',
            's',
            'm',
            'l',
            'xl',
            'xxl',
            'xxxl',
          ];

          if (
            sizes.indexOf(a.title.toLowerCase()) > -1
            && sizes.indexOf(b.title.toLowerCase()) > -1
          ) {
            return (
              sizes.indexOf(a.title.toLowerCase())
              - sizes.indexOf(b.title.toLowerCase())
            );
          } if (a.title > b.title) {
            return 1;
          } if (a.title < b.title) {
            return -1;
          }
          return 0;
        });
        variantFilters.push({
          title: this.$t('items.availableSizes'),
          current: currentSize,
          titleAttribute: 'frame_size',
          type: 'select',
          options: othersizes.map((o, i) => ({
            ...o,
            index: i,
            type: 'othersizes',
          })),
        });
      }
      if (this.item.attributes.groupset) {
        const currentGroupset = {
          relationId: 'current',
          itemId: this.item.id,
          title: this.item.tags[0],
          externalid: this.item.externalid,
          description: this.item.description,
          price: this.item.$$price.price,
          priceDifference: null,
          preorder: this.item.attributes.preorder && ['yes', 'ja', 'oui', 'sì', 'si'].includes(this.item.attributes.preorder.toLowerCase()),
        };
        let othergroupsets = [
          ...this.relations.othergroupsets ? this.relations.othergroupsets.map((o) => {
            const priceDiff = this.showRetailPrices
              ? o.$$price.msrp - this.item.$$price.msrp
              : o.$$price.price - this.item.$$price.price;
            return {
              relationId: o.relationId,
              itemId: o.itemId,
              title: o.tags[0],
              externalid: o.externalid,
              groupset: o.attributes.groupset,
              description: o.description,
              price: o.$$price.price,
              small: `${priceDiff >= 0 ? '+' : ''} ${formatPrice(priceDiff, o.$$price.currency)}`,
              preorder: o.attributes.preorder && ['yes', 'ja', 'oui', 'sì', 'si'].includes(o.attributes.preorder.toLowerCase()),
            };
          }) : [],
          currentGroupset,
          ...this.relations.othergroupsetsdifferentdesign ? this.relations.othergroupsetsdifferentdesign.map((o) => {
            const priceDiff = this.showRetailPrices
              ? o.$$price.msrp - this.item.$$price.msrp
              : o.$$price.price - this.item.$$price.price;
            return {
              relationId: o.relationId,
              itemId: o.itemId,
              title: o.tags[0],
              groupset: o.attributes.groupset,
              externalid: o.externalid,
              description: o.description,
              price: o.$$price.price,
              small: `${priceDiff >= 0 ? '+' : ''} ${formatPrice(priceDiff, o.$$price.currency)}`,
              preorder: o.attributes.preorder && ['yes', 'ja', 'oui', 'sì', 'si'].includes(o.attributes.preorder.toLowerCase()),
              different: true,
            };
          }) : [],
        ];
        // remove duplicates
        othergroupsets = othergroupsets.filter((value, index, self) => index === self.findIndex((t) => (
          t.groupset === value.groupset
        )));
        othergroupsets = othergroupsets
          .sort((a, b) => {
            if (a.title > b.title) {
              return 1;
            } if (a.title < b.title) {
              return -1;
            }
            return 0;
          })
          .sort((a, b) => a.price - b.price);

        variantFilters.push({
          title: this.attributeTranslations.groupset,
          current: currentGroupset,
          type: 'grouped_select',
          titleAttribute: 'groupset',
          options: [
            {
              label: this.$t('sameDesign'),
              options: othergroupsets.filter((o) => !o.different).map((o, i) => ({
                ...o,
                index: i,
                type: 'othergroupsets',
              })),
            },
            {
              label: this.$t('differentDesign'),
              options: othergroupsets.filter((o) => o.different).map((o, i) => ({
                ...o,
                index: i,
                type: 'othergroupsets',
              })),
            },
          ],
        });
      }
      Object.keys(this.relations).forEach((type) => {
        this.$gtag.event('view_item_list', {
          items: this.relations[type].map((o, i) => ({
            id: o.externalid,
            name: o.description,
            price: o.price,
            quantity: 1,
            list_name: type,
            list_position: i + 1,
          })),
        });
      });
      return variantFilters;
    },
  },
  methods: {
    scrollTo,
    goToItem(item) {
      const thePath = this.$route.path.split('/');
      thePath.pop();
      this.$router.push(`${thePath.join('/')}/${item.itemId}`);
      this.$gtag.event('select_content', {
        content_type: 'product',
        items: [
          {
            id: item.externalid,
            name: item.description,
            price: item.price,
            quantity: 1,
            list_name: item.type,
            list_position: parseInt(item.index, 10) + 1,
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss">
span.multiselect__option.multiselect__option--group {
    background: white !important;
    color: black !important;
    font-weight: bold;
    text-decoration: underline;
    border-top: 1px solid black;
  }
</style>
